import React from 'react';

/**
 * Page view to refer that the request was not processed successfully
 */
export const Main: React.FC = () => {
    return (
        <div className='App'>
            Hola
        </div>
    );
};
