import { useKeycloak } from '@react-keycloak/web';
import { BronsonSpinner, BronsonSpinnerProps } from '@dh/bronson-react';
//import { Redirect } from 'react-router-dom';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Main } from './main';
import Login from './login';


export const AppRouter = () => {
    const [keycloak, initialized] = useKeycloak();
    // keycloak token
    const userName = keycloak?.tokenParsed?.sub;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log('algo')
        if (initialized) {
            console.log('initialized',initialized);
            console.log('userName',userName);
            //const role = getUserRole(keycloak, dealerOnlineRoles);
            if (userName && !keycloak?.authenticated) {
                console.log('mandara a login');
                setLoading(false);
                window.location.href = '#/login';
            } else  {
                setLoading(false);
            }
        }
    }, [initialized, userName, keycloak]);

    const getRoutes = ()=> {

        if(keycloak.authenticated){
            console.log(keycloak.createAccountUrl());
            console.log(keycloak.createLoginUrl({
                redirectUri: 'https://core.cons.alondra.nar.vwfs.io/app/callback',
                scope: 'profile',
            }));
            const d = new Date();
            d.setTime(d.getTime() + (1*24*60*60*1000));
            document.cookie = `group=123456; expires=${d.toUTCString()}; domain=.alondra.nar.vwfs.io; path=/; secure`;
            console.log('se setteo la cookie');
            return <Route path='/main' component={Main} />
        }
        else {
            window.location.href = '#/login';
        }
        
    }

    if (initialized && !loading) {
        console.log('cargo router');
        return (
            <Router basename='/'>
                  <Switch>

                            {getRoutes()}
                            <Route path='/login' component={Login} />
                        </Switch>
                </Router>
        );
    } else {
        const spinnerConfig: BronsonSpinnerProps = {
            showSpinner: true,
            spinnerWrapperClassModifier: 'c-spinner--text',
            showSpinnerText: true,
            spinnerText: 'Estamos configurando tu espacio de trabajo',
        };
        return <BronsonSpinner config={spinnerConfig}></BronsonSpinner>;
    }
};
