import { KeycloakConfig } from '@dh/keycloak-auth';

/**
 * Initial configuration for the keycloak instance, this substitute the usual
 * keycloak.json that you normal put in /public
 */
 export const keycloakConfig: KeycloakConfig = {
    realm: process.env.REACT_APP_REALM || 'vwfs',
    url:
        process.env.REACT_APP_URL ||
        'https://dev.identity.digitalhub.vwfs.io/auth',
    clientId: process.env.REACT_APP_CLIENT_ID || 'alondra',
};